.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
 margin-top: 5rem;
 font-family: 'Minecraft Mono', monospace;
 text-shadow: 3px 3px 0.5px hsla(214, 76%, 49%, 0.5);
 text-align: center;
}

.App-link {
  color: #61dafb;
}